import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, Icon, Modal, Tab, Tabs } from '@mui/material';
import { format } from 'date-fns';

import { ArtistBioCard } from '@/components/cards/ArtistBioPlaceholderCard';
import AIGeneratorModal from '@/components/promote-modals/AIGeneratorModal';
import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import Card from '@/components/utility/microcomponents/Card';
import CustomIcons from '@/components/utility/microcomponents/CustomIcons';
import UserHeader from '@/components/utility/navigation/UserHeader';
import useArtist from '@/hooks/artist/useArtist';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useMarketingAssistantContent from '@/hooks/marketing-assistant/useMarketingAssistantContent';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { PromoteFlowQueryParams } from '@/models/Enums';
import { MarketingAssistantContentModel } from '@/models/MarketingAssistant';
import { useLocalStorage } from '@/services/LocalStorage';

const AIGeneratorPage = () => {
  const { t } = useTranslation();
  const { artist } = useArtist();
  const { dispatchSnackbar } = useSnackbarContext();
  const { breakpointHit } = useBreakpoints();
  const navigate = useNavigate();
  const [flowOpen, setFlowOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { getItem } = useLocalStorage();
  const [params, setSearchParams] = useSearchParams();

  const [contentGenerations, setContentGenerations] = useState<MarketingAssistantContentModel[]>([]);
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState<string>('artist-bio');
  const [open, setOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<MarketingAssistantContentModel | null>(null);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const { marketingAssistantContent, marketingAssistantContentIsLoading } = useMarketingAssistantContent();

  const navigateToAddInfo = () => {
    navigate('/profile', { state: { redirect: '/lyra', tab: 'about' } });
  };

  const openBasicInfoModal = () => {
    params.set(PromoteFlowQueryParams.BASIC_INFO, 'true');
    setSearchParams(params);
  };

  useEffect(() => {
    if (marketingAssistantContent) {
      setContentGenerations(marketingAssistantContent.items);
      setIsLoadingContent(false);
    }
  }, [marketingAssistantContent]);

  const checkFirstTime = useCallback(() => {
    const firstTime = getItem(`ai-generator-first-time-${artist?.id}`);
    if (!firstTime) {
      setIsFirstTime(true);
    } else {
      setIsFirstTime(false);
    }
  }, [artist?.id, getItem]);

  useEffect(() => {
    checkFirstTime();
  }, [checkFirstTime]);

  const openContentModal = (content: MarketingAssistantContentModel) => {
    setModalContent(content);
    setOpen(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setOpen(false);
  };

  return (
    <div data-testid="ai-generator-page" className="page-content">
      <Modal
        open={flowOpen}
        onClose={() => {
          checkFirstTime();
          setIsEditMode(false);
          setFlowOpen(false);
        }}
      >
        <>
          <AIGeneratorModal
            type={tabValue}
            isEdit={isEditMode}
            closeModalOutput={() => {
              checkFirstTime();
              setIsEditMode(false);
              setFlowOpen(false);
            }}
          />
        </>
      </Modal>
      <Modal open={open} onClose={closeModal}>
        <div className="to-do-modal p16">
          <div className="d-flex jc-space-between">
            <h4>{modalContent?.type === 0 ? 'Artist Bio' : 'Press Release'}</h4>
            <ButtonComponent
              isCustom
              className="m0"
              onClick={async () => {
                if (!modalContent) return;
                await navigator.clipboard.writeText(modalContent?.content);
                await dispatchSnackbar({
                  type: 'OPEN_SNACKBAR',
                  payload: {
                    message: t('COMMON.COPIED-TO-CLIPBOARD'),
                    type: 'success',
                  },
                });
              }}
            >
              <CustomIcons name="copy" />
            </ButtonComponent>
          </div>

          <p className="text-faded small">{modalContent?.content}</p>
        </div>
      </Modal>

      <UserHeader title="PAGE-TITLES.AI-GENERATOR" isBeta={true} />
      {marketingAssistantContentIsLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
      {contentGenerations && contentGenerations?.length === 0 && !isLoadingContent && isFirstTime && (
        <div className="to-do-card-border mt10">
          <div className={`to-do-card p16 d-flex ${breakpointHit ? 'flex-wrap' : ''}`}>
            <div>
              <h4>Add your basic information</h4>
              <p className="text-reduced-opacity">
                We use the information you tell us about yourself in the outputs we create, keep it up to date for the
                best results.
              </p>
            </div>
            {!breakpointHit && (
              <Button className="btn-white m0 ml-auto mt-auto mb-auto min-w100" onClick={openBasicInfoModal}>
                <Icon>add</Icon>
                <span className="fw-bold">Add info</span>
              </Button>
            )}
            {breakpointHit && (
              <Button className="btn-white ml0 mr0 mb0 w100p mt10" onClick={openBasicInfoModal}>
                <Icon>add</Icon>
                <span className="fw-bold">Add info</span>
              </Button>
            )}
          </div>
        </div>
      )}
      {contentGenerations && contentGenerations?.length === 0 && !isLoadingContent && (
        <div className="mt10 d-flex gap10 flex-wrap">
          <ArtistBioCard isBio />
          <ArtistBioCard />
        </div>
      )}
      {contentGenerations && contentGenerations.length > 0 && (
        <>
          <Box
            sx={{
              width: '100%',
              marginTop: '10px',
            }}
          >
            <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
              <Tab label="Artist Bio" value="artist-bio" />
              <Tab label="Press Release" value="press-release" />
              {!breakpointHit && (
                <Button className="btn-white m0 ml-auto mr0 min-w150" onClick={navigateToAddInfo}>
                  <CustomIcons className="text-black mt4" name="edit" />
                  <span className="pl8 fw-bold">Update saved info</span>
                </Button>
              )}
            </Tabs>
          </Box>
          {breakpointHit && (
            <Button className="btn-white m0 w100p mb10" onClick={navigateToAddInfo}>
              <CustomIcons className="text-black mt4" name="edit" />
              <span className="pl8">Update saved info</span>
            </Button>
          )}
          <div>
            {contentGenerations &&
              contentGenerations.filter((item) => (tabValue === 'artist-bio' ? item.type === 0 : item.type === 1))
                .length === 0 && <ArtistBioCard isBio={tabValue === 'artist-bio'} />}
            {contentGenerations &&
              contentGenerations.filter((item) => (tabValue === 'artist-bio' ? item.type === 0 : item.type === 1))
                .length > 0 && (
                <Card inner className="p16">
                  <div>
                    <div className="d-flex jc-space-between">
                      <h4>Your latest {tabValue === 'artist-bio' ? 'Artist Bio' : 'Press Release'}</h4>
                      <ButtonComponent
                        isCustom
                        className="m0"
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            contentGenerations.filter((item) =>
                              tabValue === 'artist-bio' ? item.type === 0 : item.type === 1
                            )[0].content
                          );
                          await dispatchSnackbar({
                            type: 'OPEN_SNACKBAR',
                            payload: {
                              message: t('COMMON.COPIED-TO-CLIPBOARD'),
                              type: 'success',
                            },
                          });
                        }}
                      >
                        <CustomIcons className="" name="copy" />
                      </ButtonComponent>
                    </div>
                    <p className="text-faded mt10">
                      {
                        contentGenerations.filter((item) =>
                          tabValue === 'artist-bio' ? item.type === 0 : item.type === 1
                        )[0].content
                      }
                    </p>
                  </div>
                  <div className={`d-flex ${breakpointHit ? 'jc-center' : 'jc-end'} mt10`}>
                    <Button
                      className={`btn-black min-w150 ${breakpointHit ? 'ml0' : ''}`}
                      onClick={() => {
                        setIsEditMode(true);
                        return setFlowOpen(true);
                      }}
                    >
                      <CustomIcons className="text-white mt4 pr8" name="edit" />
                      Edit {!breakpointHit && (tabValue === 'artist-bio' ? 'artist bio' : 'press release')}
                    </Button>
                    <Button
                      className="btn-white ml10 mr0 min-w150"
                      onClick={() => {
                        return setFlowOpen(true);
                      }}
                    >
                      <>
                        <CustomIcons className="text-black pr8" name="add" />
                        Create new
                      </>
                    </Button>
                  </div>
                </Card>
              )}
          </div>
          {contentGenerations.filter((item) => (tabValue === 'artist-bio' ? item.type === 0 : item.type === 1)).length >
            1 && <h4 className="mt20">Previous generations</h4>}
          {contentGenerations &&
            contentGenerations
              .filter((item) => (tabValue === 'artist-bio' ? item.type === 0 : item.type === 1))
              .slice(1)
              .map((content, index) => (
                <Card
                  key={index}
                  inner
                  className="mt10 p16 d-flex gap10 cursor-pointer"
                  onClick={() => {
                    openContentModal(content);
                  }}
                >
                  <CustomIcons className="text-white mt-auto mb-auto" name="quill" />
                  <div>
                    <p>
                      {format(new Date(content.createdAt), 'do MMM') +
                        ' at ' +
                        format(new Date(content.createdAt), 'p')}
                    </p>
                    <p className="truncate-1 text-faded" key={index}>
                      {content.content}
                    </p>
                  </div>
                </Card>
              ))}
        </>
      )}

      {/* <Button className="btn-white mt10" onClick={updateRelease}>
        Update release cycle
      </Button> */}
    </div>
  );
};

export default AIGeneratorPage;
